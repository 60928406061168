/***
 *
 *   CAIDS
 *   View CAIDs
 *
 **********/

import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
  AppLayoutContext, AuthContext, Button,
  DataSpecNumberCell,
  DateCell, FileInput, Form, Icon,
  Loader,
  ReactTable, TextInput,
  useAPI
} from 'components/lib';
import { CSVLink } from "react-csv";
import Style from "../../../components/hero/hero.tailwind";
import axios from "axios";
import Axios from "axios";
import {Dialog, Transition} from "@headlessui/react";
import fileDownload from "js-file-download";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ViewCaidScope(props){
  const context = useContext(AuthContext);

  const { customerId, caidScopeId } = useParams();
  let query = useQuery();
  const caidScope = useAPI('/api/caidScope/' + caidScopeId);
  const caidTypes = useAPI('/api/caidTypes/');
  // const caidRows = useAPI('/api/caidScope/' + caidScopeId + '/rows');
  const batches = useAPI('/api/caidScope/' + caidScopeId + '/batches');
  const customers = useAPI('/api/customer/');
  const users = useAPI('/api/user/');

  let navigate = useNavigate();
  const [generateInProgress, setGenerateInProgress] = useState(false);
  const [generationErrors, setGenerationErrors] = useState([]);
  const [orderCount, setOrderCount] = useState(undefined);
  const [extraPercent, setExtraPercent] = useState(undefined);
  const [batchFilterId, setBatchFilterId] = useState(query.get("batchId"));
  const [exportPendingMessage, setExportPendingMessage] = useState(undefined);
  const [caidRows, setCaidRows] = useState({loading: false});
  const [showDeleteBatchModal, setShowDeleteBatchModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchedCaidData, setSearchedCaidData] = useState(undefined);
  const [searchError, setSearchError] = useState(undefined);
  const [importError, setImportError] = useState(undefined);
  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)


  const [separatorOption, setseparatorOption] = useState(false);
  const [delimiter, setdelimiter] = useState('Comma');
  const [otherdelimiter, setotherdelimiter] = useState('');
  const [error_ss, seterror_ss] = useState(false);
  const [quote, setquote] = useState(null);

  const delemiterOpts = {'Tab': "\t", 'Comma': ',', 'Semicolon': ';', 'Space':' '};

  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);

  useEffect(() => {
    if (batches.data) {
      const makeAsyncRequest = async () => {
        setCaidRows({loading: true});
        let theUrl = '/api/caidScope/' + caidScopeId + '/rows';
        if (batchFilterId && batchFilterId !== '' && batchFilterId !== 'unselected') {
          theUrl = '/api/caidScope/' + caidScopeId + '/rows/' + batchFilterId;
        }
        const res = await Axios({

          url: theUrl,
          method: 'get',

        })

        setCaidRows({data: res.data.data, totalCount: res.data.totalCount, loading: false});
      }
      makeAsyncRequest();
    }
  }, [batches, batchFilterId]);

  // API Calls triggered by user
  async function generate() {
    // Validate
    const errors = [];
    if (!orderCount) {
      errors.push('Order Count was missing');
    }
    if (!extraPercent) {
      errors.push('Extra Percent was missing');
    }
    if (errors.length > 0) {
      return errors;
    }

    setGenerateInProgress(true);

    // send create request to server
    try {
      const response = await axios({

        method: 'post',
        url: `/api/caidScope/${caidScopeId}/generate`,
        data: {
          orderCount: orderCount,
          extraPercent: extraPercent,
          userId: context.user?.user_id,
        },
      });

      setGenerateInProgress(false);
      console.log(response);
      if (response.status === 200 && response.data.success) {
        window.location.reload();
        return;
      } else {
        // return error if not success
        return [{
          additionalErrorText: response.data.error ? response.data.error.error : undefined,
          message: 'Error when generating CAIDs.'
        }]
      }
    } catch (e) {
      setGenerateInProgress(false);
      return [{
        additionalErrorText: e,
        message: 'Error when generating CAIDs.'
      }]
    }
  }

  async function deleteBatch(batchId) {

    // Validate
    const errors = [];
    if (!batchId) {
      errors.push('BatchId was missing');
    }
    if (errors.length > 0) {
      return errors;
    }

    // send create request to server
    const response = await axios({

      method: 'delete',
      url: `/api/caidScope/batch/${batchId}`,
      data: {
        userId: context.user?.user_id,
      },

    });

    console.log(response);
    if (response.status === 200) {
      window.location.reload();
      return;
    }
    else if (response.status !== 200) {
      // return error if not '200 OK'
      return [{
        errorStatus: response.status,
        errorStatusText: response.data.error,
        message: 'Error when deleting batch'
      }]
    }
  }

  async function deleteCaidScope(caidScopeId) {

    // Validate
    const errors = [];
    if (!caidScopeId) {
      errors.push('CaidScopeId was missing');
    }
    if (errors.length > 0) {
      return errors;
    }

    try {
      // send create request to server
      const response = await axios({

        method: 'delete',
        url: `/api/caidScope/${caidScopeId}`,

      });

      console.log(response);
      if (response.status === 200) {
        return navigate(`/customer/${customerId}/caid`);
      }
      else if (response.status !== 200) {
        // return error if not '200 OK'
        return [{
          errorStatus: response.status,
          errorStatusText: response.data.error,
          message: 'Error when deleting CAID Scope.'
        }]
      }
    } catch (e) {
      let errorDetails = '';
      if (e.response?.data?.message && e.response.data.message.includes("violates foreign key")) {
        errorDetails = "Cannot delete CAID Scope because it has CAIDs used by orders."
      }
      else if (e.response?.data?.message) {
        errorDetails = e.response?.data?.message;
      }

      return [{
        additionalErrorText: errorDetails,
        message: 'Error when deleting CAID Scope.'
      }]
    }
  }

  async function renameCaidScope(caidScopeId, name) {

    // Validate
    const errors = [];
    if (!caidScopeId) {
      errors.push('caidScopeId was missing');
    }
    if (!name) {
      errors.push('name was missing');
    }
    if (errors.length > 0) {
      return errors;
    }

    try {
      // send create request to server
      const response = await axios({

        method: 'patch',
        url: `/api/caidScope/${caidScopeId}`,
        data: {
          userId: context.user?.user_id,
          name: name,
        },

      });

      console.log(response);
      if (response.status === 200) {
        return window.location.reload();
      }
      else if (response.status !== 200) {
        // return error if not '200 OK'
        return [{
          errorStatus: response.status,
          errorStatusText: response.data.error,
          message: 'Error when renaming CAID Scope'
        }]
      }
    } catch (e) {
      return [{
        additionalErrorText: e.response.data.message,
        message: 'Error when renaming CAID Scope'
      }]
    }
  }

  async function exportAllValues(caidScopeId, batchId) {
    setExportPendingMessage('Waiting')

    // send download request to server
    const response = await axios({

      method: 'get',
      url: `/api/caidScope/${caidScopeId}/export${batchId && batchId !== '' && batchId !== 'unselected' ? '/' + batchId : ''}`,
      responseType: 'blob',

    });
    setExportPendingMessage(undefined);
    fileDownload(response.data, `caidScope_export_${batchId && batchId !== '' && batchId !== 'unselected' ? 'batchId_' + batchId : caidScopeId}.csv`);
  }

  async function search() {
    if(!searchValue || searchValue.length < 4) {
      setSearchError('Search value must be at least 4 length.');
      return;
    }
    setSearchError(undefined);

    const response = await axios({

      method: 'get',
      url: `/api/caidScope/${caidScopeId}/search/${searchValue}`,

    });
    console.log(response);
    if (response.status === 200) {
      setSearchedCaidData(response.data.data);
    }
  }

  const columns = React.useMemo(() => [
    {
      Header: "Value",
      accessor: (row) => {
        if(row.nonUniqueValue!=null){
          return row.nonUniqueValue && row.value.length > 130 ? row.nonUniqueValue.slice(0,130) + '...': row.nonUniqueValue;
        }else {
          return row.value && row.value.length > 130 ? row.value.slice(0, 130) + '...' : row.value;
        }
      },
    },
    {
      Header: "Generation Time",
      accessor: (row) => {
        return `${new Date(row.caidBatch.generation_timestamp).toLocaleString()} ${row.caidBatch.is_manual_import ? '(manual import)' : ''}`;
      },
    },
    {
      Header: "UID(Provide By factory)",
      accessor: (row) => {
        if(row.uid.includes("null_")){
          return "";
        }
        return row.uid && row.uid.length > 130 ? row.uid.slice(0,130) + '...': row.uid;
      },
    },
    {
      Header: "Bundle ID",
      accessor: (row) => {
        return row.genBundleID && row.genBundleID.length > 130 ? row.genBundleID.slice(0,130) + '...': row.genBundleID;
      },
    },
    {
      Header: "Created By",
      accessor: (row) => {
        return row && row.caidBatch.created_by_id && users.data ? users.data.find(u => u.id === row.caidBatch.created_by_id).name: row.caidBatch.created_by_id
      },
    },

  ], [users, caidRows])


  return (
      <Fragment>

        { caidScope.loading || batches.loading || caidRows.data == null || caidRows.loading || users.loading || customers.loading || caidTypes.loading ?
            <Loader /> :
            <>
              <div className={"flex mb-4 -mt-2"}>
                <a className={"relative text-sm hover:underline text-orange-500"}
                   href={`/customers`}>
                  Customers
                </a>
                <span className={"mx-2 text-sm text-gray-400"}> / </span>
                <a className={"relative text-sm hover:underline text-orange-500"}
                   href={`/customer/${customerId}`}>
                  {customers.data ? customers.data.find(u => u.id === customerId).name: customerId}
                </a>
                <span className={"mx-2 text-sm text-gray-400"}> / </span>
                <a className={"relative text-sm hover:underline text-orange-500"}
                   href={`/customer/${customerId}/caid`}>
                  Manage CAID Scopes
                </a>
                <span className={"mx-2 text-sm text-gray-400"}> / </span>
                <p className={"relative text-sm text-gray-500"}>
                  {caidScope.data.name}
                </p>
              </div>
              <div className={"flex mb-4"}>
                <div className={"w-72 space-y-2 shadow bg-gray-50 rounded-lg p-4"}>
                  <div className={"flex mb-3 items-center justify-between"}>
                    <h3 className={"text-xl font-semibold leading-6 text-gray-900"}>Details</h3>
                    <div className={"flex items-center"}><button
                        type="button"
                        className="inline-flex items-center bg-gray-50 rounded-md text-sm font-medium text-orange-500 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200"
                        onClick={() => {
                          setShowSettingsModal(true);
                        }}
                    >
                      <Icon image={'settings'} size={12} className={Style.labelIcon + " mr-0.5"}/>
                      Edit
                    </button></div>
                  </div>
                  <div className={"flex gap-8"}>
                    <div  className={"flex-col mt-0"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Name
                      </div>
                      <div className={"text-gray-500"}>
                        {caidScope.data.name}
                      </div>
                    </div>
                    <div  className={"flex-col mt-0"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Total Count
                      </div>
                      <div className={"text-gray-500"}>
                        {caidScope.totalCount && parseInt(caidScope.totalCount) ? `${parseInt(caidScope.totalCount).toLocaleString()} CAIDs` : '-'}
                      </div>
                    </div>
                  </div>
                  {caidScope.data.description  && <div className={"flex gap-8"}>
                    <div  className={"flex-col mt-2"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Description
                      </div>
                      <a className={"relative block text-sm hover:underline text-orange-500"}
                         href={`/customer/${customerId}`}>
                        {caidScope.data.description || '-'}
                      </a>
                    </div>
                  </div>}
                  <div className={"flex gap-8"}>
                    <div  className={"flex-col mt-2"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Customer
                      </div>
                      <a className={"relative block text-sm hover:underline text-orange-500"}
                         href={`/customer/${customerId}`}>
                        {customers.data ? customers.data.find(u => u.id === customerId).name: customerId}
                      </a>
                    </div>
                  </div>
                  <div className={"flex gap-8"}>
                    <div  className={"flex-col mt-2 w-24"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        CAID Type
                      </div>
                      <div className={"text-gray-500"}>
                        {caidTypes.data.find(u => u.id === caidScope.data.caidFormat.caid_type_id).name}
                      </div>
                    </div>
                    <div  className={"flex-col mt-2 w-24"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Min Length
                      </div>
                      <div className={"text-gray-500"}>
                        {caidScope.data.caidFormat.min_length || '-'}
                      </div>
                    </div>
                    <div  className={"flex-col mt-2 w-24"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Min Length
                      </div>
                      <div className={"text-gray-500"}>
                        {caidScope.data.caidFormat.max_length || '-'}
                      </div>
                    </div>
                  </div>
                  <div className={"flex gap-8"}>
                    <div  className={"flex-col mt-2 w-24"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Leading Zeros
                      </div>
                      <div className={"text-gray-500"}>
                        {caidScope.data.caidFormat.allow_leading_zeros || '-'}
                      </div>
                    </div>
                    <div  className={"flex-col mt-2 w-24"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Prefix
                      </div>
                      <div className={"text-gray-500"}>
                        {caidScope.data.caidFormat.prefix || '-'}
                      </div>
                    </div>
                    <div  className={"flex-col mt-2 w-24"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Suffix
                      </div>
                      <div className={"text-gray-500"}>
                        {caidScope.data.caidFormat.suffix || '-'}
                      </div>
                    </div>
                  </div>
                </div>
                {!query.get("batchId") && <div className={"w-64 ml-4 shadow bg-gray-50 rounded-lg p-3"}>
                  <h2 className={""}>Generate CAIDs</h2>
                  <TextInput placeholder={"Number to Gen."} value={orderCount} onChange={(name, value) => setOrderCount(value)} outerClassName={"mr-2 mt-2"} />
                  <TextInput placeholder={"Extra %"} value={extraPercent} onChange={(name, value) => setExtraPercent(value)} outerClassName={"mr-2 mt-2"} />
                  <Button action={async () => {
                    const errors = await generate();
                    if (errors) {
                      setGenerationErrors(errors);
                    }
                  }} text='Generate' small
                          className={ Style.button + " mt-2 mr-2 relative bg-orange-500 hover:bg-orange-700 w-full " + (generateInProgress ? 'bg-gray-500':'')}/>
                  {generateInProgress && <Loader classNames={`w-6 h-6 absolute ml-2 -mt-9`} />}
                  {(generationErrors && generationErrors.length > 0) && <div className={"flex text-red-500"}>
                    {
                      generationErrors.map((error, index) => {
                        return (
                            <div key={'generationError_' + index}>
                              {error.message ? error.message : error}
                              <br />
                              {error.additionalErrorText}
                            </div>
                        );
                      })
                    }
                  </div>}
                </div>}
                {!query.get("batchId") && <div className={"w-64 space-y-2 ml-4 shadow bg-gray-50 rounded-lg p-3"}>
                  <h2 className={""}>Import Existing</h2>
                  <Form
                      className={"p-1 bg-white space-y-2 sm:rounded-lg"}
                      flex={true}
                      url={`/api/caidScope/${caidScopeId}/import`}
                      method='POST'
                      buttonTextSm='Upload'
                      callback={() => {
                        window.location.reload();
                      }}
                      handleError={(error) => {
                        if (error.response && error.response.data) {
                          if (typeof error.response.data.error === 'string') {
                            setImportError(error.response.data.error);
                          }
                          else if (error.response.data.error && typeof error.response.data.error.detail === 'string') {
                            setImportError(error.response.data.error.detail);
                          }
                        }
                      }}
                      data={{
                        idsFile: {
                          label: '',
                          type: 'file',
                          required: true,
                          errorMessage: 'Please upload a file',
                        },
                        userId: {
                          label: 'userID',
                          type: 'hidden',
                          required: true,
                          value: context.user?.user_id,
                        },
                      }}>
                  </Form>
                  {importError && <div className={"flex text-xs text-red-500"}>{importError}</div>}
                </div>}
                <div className={"w-fill min-w-[400px] space-y-2 ml-4 shadow bg-gray-50 rounded-lg p-3"}>
                  <h2 className={""}>Search CAID Value</h2>
                  <input type={'text'} className={'w-full p-3 rounded bg-white border border-solid border-gray-300 focus:bg-slate-50 appearance-none disabled:opacity-50'}
                         placeholder={"Search for matching values"}
                         value={searchValue} onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  />
                  <Button action={async () => {
                    await search();
                  }}
                          text='Search' small
                          className={ Style.button + " mt-2 mr-2 relative bg-orange-500 hover:bg-orange-700 w-full "}/>
                  {searchError && <div className={"flex text-red-500"}>{searchError}</div>}
                  {searchedCaidData && searchedCaidData.length > 0 && <div className={"flex flex-col"}>
                    <div className={"grid grid-cols-2 justify-center text-center"}>
                      <div className={"text-sm mb-0 uppercase text-gray-700"}>
                        Caid Value
                      </div>
                      <div className={"text-sm mb-0 uppercase text-gray-700"}>
                        Batch Info
                      </div>
                    </div>
                    {searchedCaidData.map((d, index) => {
                      return (
                          <div key={d.value} className={"flex justify-evenly -mx-1 rounded gap-x-3 mt-1 p-1 " + (index%2 === 0 ? 'bg-gray-100' : '')}>
                            <div className={"text-xs text-gray-900"}>{d.value}</div>
                            <div className={"flex-col"}>
                              <div className={"text-xs text-gray-500 justify-between"}>{d.caid_batch_id}</div>
                              <div className={"text-xs text-gray-400 justify-between"}>{new Date(d.caidBatch.generation_timestamp).toLocaleString()}</div>
                            </div>
                          </div>
                      )
                    })}
                  </div>}
                </div>
              </div>
              <div className={"space-y-2 shadow bg-gray-50 rounded-lg p-3"}>
                <div className={"flex flex-col mb-4"}>
                  <div className={"flex mb-4"}>
                    <div className={"flex flex-col"}>
                      <div className={"text-xl font-semibold leading-6 text-gray-900"}>
                        CAID Values
                      </div>
                      <div className={"text-sm text-gray-400"}>
                        Preview of first 1K values {caidRows.totalCount && parseInt(caidRows.totalCount) ? `(out of ${parseInt(caidRows.totalCount).toLocaleString()} total)` : ''}
                      </div>
                    </div>
                    <Form
                        updateOnChange={true}
                        onChange={(e) => {
                          setBatchFilterId(e.value);
                        }}
                        className={"bg-gray-50 rounded-sm w-52 ml-16"}
                        flex={false}
                        data={{
                          customer_id: {
                            label: "Filter by Batch",
                            type: 'select',
                            options: (!batches.loading && batches.data) ? batches.data.map(x => {return {value: x.id, label: `${new Date(x.generation_timestamp).toLocaleString()} ${x.is_manual_import ? '(manual import)' : ''}` }}) : [],
                            required: false,
                            value: batchFilterId || '',
                            errorMessage: 'Please select a batch',
                            compact: true
                          },
                        }}>
                    </Form>
                    {batchFilterId && batchFilterId !== '' && batchFilterId !== 'unselected' &&
                        <button
                            type="button"
                            className="ml-6 inline-flex align-top items-center bg-gray-50 rounded-md text-md font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200"
                            onClick={() => {
                              setShowDeleteBatchModal(true);
                            }}
                        >
                          <Icon image={'trash'} size={18} className={Style.labelIcon}/>
                          Delete Batch
                        </button>
                    }
                  </div>
                  <div className={"flex"}>

                    <CSVLink className={ "cursor-pointer align-top font-sans text-sm px-5 w-fit h-12 bg-orange-500 hover:bg-orange-700 relative text-center font-semibold rounded px-12 py-3 ease-in-out duration-500 text-white"}
                             filename={`export_caids_${customers.data.find(u => u.id === customerId).name}_${caidScope.data.name}`}
                             data={caidRows.data.map(row => {
                               return {
                                 value: row.value,
                                 batchTimestamp: row.caidBatch.generation_timestamp,
                                 batchId: row.caid_batch_id,
                               }
                             })}
                             separator={delimiter=="Other" ? otherdelimiter : delemiterOpts[delimiter]} enclosingCharacter={quote===null ? '' : quote}
                    >Export preview as CSV
                    </CSVLink>
                    {caidRows.data && caidRows.data.length >= 999 && <Button
                        action={async () => {
                          await exportAllValues(caidScopeId, batchFilterId);
                        }}
                        text={'Export ALL values as CSV'}
                        small
                        className={`cursor-pointer align-top font-sans text-sm px-5 w-fit h-12 ml-4 text-center  
                  ${!exportPendingMessage ? 'border border-gray-300 text-gray-700 bg-indigo-500 hover:bg-indigo-700' : 'border border-transparent bg-orange-500 hover:bg-orange-700'}`}/>}
                  </div>
                  <div class={"flex"}>
                    <div className={""}>
                      <Form
                          updateOnChange={true}
                          onChange={(e) => {
                            setseparatorOption(e.value);
                          }}
                          className={`p-2 rounded-sm w-72 inline-block`}
                          flex={false}
                          data={{
                            caidScopes: {
                              label: 'Separator Options',
                              type: 'singleCheckbox',
                              value: separatorOption,
                              required: false,
                              errorMessage: 'Please select an option',
                            },

                          }}>
                      </Form>
                      {separatorOption &&
                          <div className={"SeparatorOption mb-4"} >
                            <Form
                                updateOnChange={true}
                                onChange={(e) => {
                                  // console.log("SelectedSSSSS:"+JSON.stringify(e))
                                  if(e.input=="caidScopes"){
                                    if(e.value==''){
                                      setquote(null);
                                    }else{
                                      if(e.value=="(" || e.value==")"){
                                        seterror_ss(true);
                                      }else{
                                        seterror_ss(false);
                                      }
                                      setquote(e.value);
                                    }
                                  }else{
                                    if(e.input=='otherSeperator'){
                                      setotherdelimiter(e.value);
                                    }else{
                                      setdelimiter(e.value);
                                      setotherdelimiter('');
                                    }
                                  }

                                }}
                                className={`p-2 w-72 inline-block`}
                                flex={true}
                                data={{
                                  separator_ss: {
                                    type: 'radio',
                                    options: ["Tab", "Comma", "Semicolon", "Space", "Other"],
                                    required: false,
                                    errorMessage: 'Please select an option',
                                    value:delimiter,
                                    label: 'Separated by',
                                  },
                                  otherSeperator: {
                                    label: '',
                                    type: 'text',
                                    value: otherdelimiter,
                                    isHidden:delimiter!="Other",
                                    required: false,
                                    errorMessage: 'Separated by',
                                  },
                                  caidScopes: {
                                    label: 'String Delimiter',
                                    type: 'text',
                                    value: quote==null ? '' : quote,
                                    required: false,
                                    errorMessage: 'Please enter string delimiter',
                                  },
                                }}
                            >
                            </Form>
                            <span class="error_ss"  style={{display: error_ss==true?'block':'none'}}>Unsupported String Delimeter</span>
                          </div>

                      }

                    </div>
                  </div>
                </div>
                <ReactTable columns={columns} data={caidRows.data} />
              </div>
              {batches.data && batchFilterId && batchFilterId !== 'unselected' &&
                  <DeleteBatchModal open={showDeleteBatchModal}
                                    setOpen={setShowDeleteBatchModal}
                                    size={caidRows.data.length}
                                    description={`${new Date(batches.data.find(x => x.id === batchFilterId).generation_timestamp).toLocaleString()}`}
                                    onClick={async () => {
                                      await deleteBatch(batchFilterId);
                                      setShowDeleteBatchModal(false);
                                    }}
                  />}
              <SettingsModal open={showSettingsModal}
                             setOpen={setShowSettingsModal}
                             hasCaidData={batches.data && batches.data.length > 0}
                             onDelete={async () => {
                               return await deleteCaidScope(caidScopeId);
                             }}
                             onRename={async (name) => {
                               return await renameCaidScope(caidScopeId, name);
                             }}
              />
            </>
        }
      </Fragment>
  )
}

function SettingsModal (props) {

  const cancelButtonRef = useRef(null);
  const [nameInput, setNameInput] = useState('');
  const [deleteErrors, setDeleteErrors] = useState(undefined);
  const [renameErrors, setRenameErrors] = useState(undefined);

  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                    Settings
                  </Dialog.Title>
                </div>

                <div className="mt-4">
                  <p className="text-base font-medium mb-2">Rename CAID Scope</p>
                  <input type={'text'} className={'w-96 mb-2 p-3 rounded bg-white border border-solid border-gray-300 focus:bg-slate-50 appearance-none disabled:opacity-50'}
                         placeholder={"Enter new name"}
                         value={nameInput} onChange={(event) => {
                    setNameInput(event.target.value);
                  }}
                  />
                  <button
                      type="button"
                      className="w-48 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                      onClick={async () => {
                        setRenameErrors(undefined);
                        const errors = await props.onRename(nameInput);
                        if (errors) {
                          setRenameErrors(errors);
                        }
                      }}
                  >
                    Rename
                  </button>
                  {(renameErrors && renameErrors.length > 0) && <div className={"flex mt-4 text-red-500 text-xs"}>
                    {
                      renameErrors.map((error, index) => {
                        return (
                            <div key={'renameError_' + index}>
                              {error.message ? error.message : error}
                              <br />
                              {error.additionalErrorText}
                            </div>
                        );
                      })
                    }
                  </div>}
                </div>

                <div className="mt-12">
                  <p className="text-base mb-0">Delete CAID Scope</p>
                  <p className="text-sm text-red-600 mb-4"><strong className={"text-red-600"}>WARNING</strong> - This action cannot be undone.</p>
                  <button
                      type="button"
                      className="w-48 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                      onClick={async () => {
                        setDeleteErrors(undefined);
                        const errors = await props.onDelete();
                        if (errors) {
                          setDeleteErrors(errors);
                        }
                      }}
                  >
                    Delete
                  </button>
                  {(deleteErrors && deleteErrors.length > 0) && <div className={"flex mt-4 text-red-500 text-xs"}>
                    {
                      deleteErrors.map((error, index) => {
                        return (
                            <div key={'deleteError_' + index}>
                              {error.message ? error.message : error}
                              <br />
                              {error.additionalErrorText}
                            </div>
                        );
                      })
                    }
                  </div>}
                </div>
                <div className="mt-12">
                  <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
  )
}

function DeleteBatchModal (props) {

  const cancelButtonRef = useRef(null);

  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="">
                    <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                      Are you sure you want to proceed with deletion?
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className="text-base mb-2">Delete CAID Batch - {props.description}</p>
                      <p className="text-base font-semibold mb-2">{props.size.toLocaleString()} CAIDs to be deleted.</p>
                      <p className="text-base mb-4">This action cannot be undone.</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                      onClick={() => props.onClick()}
                  >
                    Delete {props.size.toLocaleString()} CAIDs
                  </button>
                  <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
